import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useSettings = defineStore('settings', () => {
  const isMarketingActive = ref(false)
  const isKnowledgeActive = ref(false)

  const storageMarketingActive = sessionStorage.getItem('isMarketingActive')
  const storageKnowledgeActive = sessionStorage.getItem('storageKnowledgeActive')

  if (storageMarketingActive) {
    isMarketingActive.value = JSON.parse(storageMarketingActive)
  }

  if (storageKnowledgeActive) {
    isKnowledgeActive.value = JSON.parse(storageKnowledgeActive)
  }

  watch(isMarketingActive, async (newStatus) => {
    sessionStorage.setItem('isMarketingActive', JSON.stringify(newStatus))
  })

  watch(storageKnowledgeActive, async (newStatus) => {
    sessionStorage.setItem('storageKnowledgeActive', JSON.stringify(newStatus))
  })

  return {
    isMarketingActive,
    isKnowledgeActive
  }
})
