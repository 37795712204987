<template>
  <div class="cart-page">
    <custom-header class="cart-title" :title="'Корзина'"></custom-header>
    <div class="cart-page__wrapper" :class="{ loading: isLoading }">
      <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
      <div class="cart-page__topic">
        <p v-if="isOrderAvailable" class="cart-page__topic__name">Предзаказ</p>
        <p v-else class="cart-text">В корзине нет товаров</p>
        <transition>
          <button
            v-show="isOrderAvailable"
            class="cart-page__topic__clear-btn"
            @click="clearCart()"
          >
            Очистить корзину
          </button>
        </transition>
      </div>
      <cart-item
        v-for="good in cartGoods"
        :key="parseInt(good.id)"
        :id="parseInt(good.id)"
        :images="good.images"
        :articul="good.articul"
        :brand="good.brand"
        :multiplicity="good.multiplicity"
        :unit="good.unit"
        :name="good.name"
        :price="good.price"
        :amount="good.amount"
        @amountChange="(id, amount) => onAmountChange(cartGoods, id, amount)"
        @itemRemoved="onGoodDelete"
      ></cart-item>
      <section class="cart-info">
        <div class="cart-info__row">
          <p class="cart-info__row__label">Товаров</p>
          <p class="cart-info__row__value">{{ getAllGoodAmount }}</p>
        </div>
        <div class="cart-info__row">
          <p class="cart-info__row__label">Сумма</p>
          <p class="cart-info__row__value">{{ parseInt(getFullSum).toLocaleString('ru-RU') }} ₽</p>
        </div>
        <!-- <div class="cart-info__bottom">
            <v-checkbox :checkbox-id="'printed_catalog'" :checkbox-name="'printed-catalog'"
                :checkbox-label="'Запросить печатный каталог'" :checkbox-value="'need-catalog'"
                @changed="onCheckboxChanged"></v-checkbox>
              <amount-block :amount="printedCatalogAmount" @amountChange="onCatalogAmountChange"></amount-block>
          </div> -->
        <button
          class="cart-info__button"
          @click="OnOrderClick"
          :class="{ disable: !isOrderAvailable }"
        >
          Перейти к оформлению заказа
        </button>
      </section>
    </div>
    <section
      class="cart-page__other-goods"
      v-if="
        isOrderAvailable &&
        (similarGoods.length > 0 || saleGoods.length > 0 || relatedGoods.length > 0)
      "
    >
      <template v-if="similarGoods.length > 0">
        <custom-header :title="'Аналогичные товары'"></custom-header>
        <div class="good-container" :class="{ loading: isLoading }">
          <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
          <goods-slider
            :goods="similarGoods"
            @addedToCart="(id) => onAddedToCart(similarGoods, id)"
            @amount-change="(id, amount) => onAmountChange(similarGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
      <template v-if="relatedGoods.length > 0">
        <custom-header :title="'Сопутствующие товары'"></custom-header>
        <div class="good-container" :class="{ loading: isLoading }">
          <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
          <goods-slider
            :goods="relatedGoods"
            @addedToCart="(id) => onAddedToCart(relatedGoods, id)"
            @amount-change="(id, amount) => onAmountChange(relatedGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
      <template v-if="saleGoods.length > 0">
        <custom-header :title="'Товары по акции'"></custom-header>
        <div class="good-container" :class="{ loading: isLoading }">
          <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
          <goods-slider
            :goods="saleGoods"
            @addedToCart="(id) => onAddedToCart(saleGoods, id)"
            @amount-change="(id, amount) => onAmountChange(saleGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import CustomHeader from '../components/CustomHeader.vue'
import AmountBlock from '../components/AmountBlock.vue'
import CartItem from '../components/CartItem.vue'
import vCheckbox from '../components/v-checkbox.vue'
import vLoading from '../components/v-loading.vue'
import GoodsSlider from '../components/GoodsSlider.vue'

import { mapWritableState, mapState, mapActions } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'
import { useOrderInfo } from '@/stores/orderInfo'
import { useBreadcrumbs } from '../stores/breadcrumbs'

import { serverRequest } from '../api/request'

export default {
  name: 'CartView',
  components: {
    ProductCard,
    CustomHeader,
    AmountBlock,
    CartItem,
    vCheckbox,
    vLoading,
    GoodsSlider
  },

  data() {
    return {
      printedCatalogAmount: 1,
      needPrintedCatalog: false,
      isLoading: false,
      cartGoodsOld: [
        {
          id: 1,
          amount: 1,
          articul: 'FBK0500219',
          multiplicity: '1/10 шт',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp'
        },
        {
          id: 2,
          amount: 1,
          articul: 'FBK0500219',
          multiplicity: '1/10 шт',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp'
        },
        {
          id: 3,
          amount: 4,
          articul: 'FBK0500219',
          multiplicity: '1/10 шт',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp'
        },
        {
          id: 4,
          amount: 1,
          articul: 'FBK0500219',
          multiplicity: '1/10 шт',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp'
        },
        {
          id: 5,
          amount: 2,
          articul: 'FBK0500219',
          multiplicity: '1/10 шт',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp'
        }
      ],
      goods: [
        {
          id: 1,
          amount: 1,
          articul: 'FBK0500219',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp',
          added: false
        },
        {
          id: 2,
          amount: 1,
          articul: 'FBK0500219',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp',
          added: false
        },
        {
          id: 3,
          amount: 1,
          articul: 'FBK0500219',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp',
          added: false
        },
        {
          id: 4,
          amount: 1,
          articul: 'FBK0500219',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp',
          added: false
        },
        {
          id: 5,
          amount: 1,
          articul: 'FBK0500219',
          name: 'Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка',
          price: '1496',
          image: 'good.webp',
          added: false
        }
      ],

      relatedGoods: [],
      similarGoods: [],
      saleGoods: []
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'cart',
        name: 'Корзина'
      }
    ]
  },

  mounted() {
    this.checkProducts()

    console.log(this.cartGoods)
  },

  computed: {
    ...mapWritableState(useAddedGoods, ['cartGoods']),
    ...mapWritableState(useOrderInfo, { storeOrderNumber: 'orderNumber' }),
    ...mapWritableState(useOrderInfo, { storeOrderDate: 'orderDate' }),
    ...mapWritableState(useOrderInfo, { storeOrderProducts: 'orderProducts' }),
    ...mapWritableState(useOrderInfo, { storeCommercialOffer: 'сommercialOffer' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    getAllGoodAmount() {
      let amount = 0
      this.cartGoods.forEach((item) => {
        amount += item.amount
      })

      return amount
    },

    getFullSum() {
      let sum = 0
      this.cartGoods.forEach((item) => {
        sum += item.amount * item.price
      })

      return sum
    },

    isOrderAvailable() {
      if (this.getAllGoodAmount === 0) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    ...mapActions(useOrderInfo, { storeUpdateGood: 'updateGood' }),

    checkProducts() {
      for (let good of this.goods) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        console.log(cartGood)
        console.log(cartGood != undefined)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }
    },

    onAddedToCart(goods, id) {
      let good = goods.find((item) => item.id === id)
      good.added = !good.added

      this.checkProducts()
    },

    onAmountChange(goods, id, amount) {
      let good = goods.find((item) => item.id === id)
      good.amount = amount

      this.checkProducts()
    },

    onGoodDelete(id) {
      let good = this.cartGoods.find((item) => item.id === id)
      good.added = !good.added
    },

    clearCart() {
      this.cartGoods = []
      this.checkProducts()
    },

    onCatalogAmountChange(amount) {
      this.printedCatalogAmount = amount
    },

    onCheckboxChanged(status) {
      this.needPrintedCatalog = status
    },

    OnOrderClick() {
      if (this.isOrderAvailable) {
        this.createOrder()
      }
    },

    async createOrder() {
      this.isLoading = true

      let productArr = []

      this.storeOrderProducts = this.cartGoods

      console.log(this.storeOrderProducts)

      for (let good of this.storeOrderProducts) {
        let newProduct = {
          id: good.id,
          amount: good.amount
        }
        productArr.push(newProduct)
      }

      let formData = {
        products: productArr
      }

      console.log(formData)

      let result = await serverRequest('POST', 'json', 'create_order', formData)

      if (result != 'error' && result != 'unathorized') {
        this.storeOrderNumber = result.order_number
        this.storeOrderDate = result.order_date
        // if (result.commercial_offer) {
        //   this.storeCommercialOffer = result.commercial_offer
        // }

        for (let good of result.products) {
          this.storeUpdateGood(good.id, good.price, good.amount)
        }

        this.clearCart()

        this.$router.push({ name: 'order' })
      } else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.cart-page {
  min-width: 0;
  @extend %white-bg-block;

  min-width: 0;
  width: 100%;

  height: fit-content;

  position: relative;

  &.loading {
    pointer-events: none;
  }

  .cart-title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  .good-container {
    position: relative;

    min-width: 0;

    &.loading {
      pointer-events: none;
    }
  }

  .cart-text {
    @extend %text-medium;
  }

  &__topic {
    margin-top: 44px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__name {
      @extend %H2;
      color: var(--bw0);
    }

    &__clear-btn {
      @extend %text-regular;
      color: var(--bw1);

      background-color: var(--bw4);

      padding: 6px 12px;

      border-radius: 6px;
    }
  }

  .cart-item {
    margin-top: 12px;

    &:first-of-type {
      margin-top: 16px;
      padding-top: 0;
      border-top: none;
    }
  }

  &__other-goods {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid var(--bw3);

    min-width: 0;

    .custom-header {
      &:not(:first-of-type) {
        margin-top: 32px;
      }
    }

    .goods-container {
      margin-top: 24px;
    }
  }

  .block-placeholder {
    margin-top: 32px;

    @extend %text-medium;

    padding-bottom: 100px;
  }
}

.cart-info {
  margin: 40px 0 0 auto;

  padding: 24px;

  width: 100%;
  max-width: 450px;

  background-color: var(--bw4);

  border-radius: 6px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 8px;

    border-bottom: 1px solid var(--bw3);

    &__label {
      @extend %H2;
      color: var(--bw1);
    }

    &__value {
      @extend %H2;
      color: var(--bw0);
    }

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }

  &__bottom {
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    @extend %button;

    margin-top: 24px;

    user-select: none;

    &.disable {
      background-color: var(--primary-bg);
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 1600px) {
  .cart-info {
    max-width: 400px;
  }
}

@media screen and (max-width: 800px) {
  .cart-page {
    .cart-info {
      max-width: 100%;
    }
  }
}
</style>
