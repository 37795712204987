<template>
  <swiper
    class="product-slider"
    :modules="[Thumbs]"
    :thumbs="{ swiper: thumbsSwiper }"
    :slides-per-view="1"
    :space-between="30"
    @swiper="setMainSwiper"
  >
    <template v-if="images.length > 0">
      <swiper-slide v-for="image in images">
        <fancy-box class="box">
          <a data-fancybox="gallery" :href="image">
            <img :src="image" alt="изображение продукта" />
          </a>
        </fancy-box>
      </swiper-slide>
    </template>
    <swiper-slide v-else>
      <!-- <fancy-box class="box">
                <a data-fancybox="gallery" href="/src/assets/icons/img-placeholder.svg">
                    <img class="placeholder" src="@/assets/icons/img-placeholder.svg" alt="изображение продукта" />
                </a>
            </fancy-box> -->
      <div class="box">
        <img
          class="placeholder"
          src="@/assets/icons/img-placeholder.svg"
          alt="изображение продукта"
        />
      </div>
    </swiper-slide>
  </swiper>
  <swiper
    class="thumbs-slider"
    :modules="[Thumbs]"
    watch-slides-progress
    @swiper="setThumbsSwiper"
    :slides-per-view="'auto'"
    :space-between="12"
  >
    <template v-if="images.length > 0">
      <swiper-slide v-for="image in images"
        ><img :src="image" alt="изображение продукта"
      /></swiper-slide>
    </template>
    <swiper-slide v-else
      ><img class="placeholder" src="@/assets/icons/img-placeholder.svg" alt="изображение продукта"
    /></swiper-slide>
  </swiper>
</template>

<script>
import { ref, toRefs, toRef, defineProps, watch } from 'vue'
import { Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import FancyBox from './FancyBox.vue'

import 'swiper/css'

export default {
  name: 'SwiperSlider',
  components: {
    Swiper,
    SwiperSlide,
    FancyBox
  },

  props: {
    images: {
      type: Array,
      default() {
        return []
      }
    }
  },

  // data() {
  //     return {
  //         thumbsSwiper: null,
  //         mainSwiper: null
  //     }
  // },

  // mounted() {
  //     console.log(this.images);
  // },

  // methods: {
  //     setThumbsSwiper(swiper) {
  //         thumbsSwiper = swiper;
  //     },
  //     setMainSwiper(swiper) {
  //         mainSwiper = swiper;
  //     }
  // },

  setup(props) {
    const imagesArr = props.images

    const thumbsSwiper = ref(null)
    const mainSwiper = ref(null)
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper
    }
    const setMainSwiper = (swiper) => {
      mainSwiper.value = swiper
    }

    return {
      Thumbs,
      imagesArr,
      mainSwiper,
      thumbsSwiper,
      setThumbsSwiper,
      setMainSwiper
    }
  }
}
</script>

<style lang="scss">
.product-slider {
  margin-top: 16px;

  width: 450px;
  height: 450px;

  .box {
    height: 100%;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .placeholder {
    background-color: $bw4;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    border-radius: 7px;
  }
}

.thumbs-slider {
  margin-top: 16px;

  width: 100%;

  .swiper-slide {
    width: 72px;
    height: 72px;
    border-radius: 4px;

    border: 1px solid transparent;

    transition: border 0.3s;

    cursor: pointer;

    &.swiper-slide-thumb-active {
      border-color: var(--primary);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  .placeholder {
    background-color: $bw4;
  }
}

@media screen and (max-width: 800px) {
  .product-slider {
    width: 100%;
    height: 740px;
  }

  .thumbs-slider {
    margin-top: 16px;

    width: 100%;

    .swiper-slide {
      width: 72px;
      height: 72px;
    }
  }
}

@media screen and (max-width: 600px) {
  .product-slider {
    width: 347px;
    height: auto;
    min-height: 320px;
    max-height: 347px;
  }

  .thumbs-slider {
    margin-top: 16px;

    width: 100%;

    .swiper-slide {
      width: 72px;
      height: 72px;
    }
  }
}
</style>
