<template>
  <div class="home-page">
    <a :href="storeMainBanner.link" class="main-page__banner-link">
      <!-- <img
        v-if="storeMainBanner.banner != ''"
        class="main-page__banner"
        :src="storeMainBanner.banner"
        alt="big banner"
      /> -->
      <img
        v-if="storeMainBanner.banner"
        class="main-page__banner"
        :src="storeMainBanner.banner"
        alt="big banner"
      />
      <div v-else class="main-page__banner main-page__banner_placeholder" />
    </a>
    <main-search class="main-page__search" :color="'white'"></main-search>
    <div class="main-page__container">
      <section class="order-history-block" :class="{ loading: isOrdersLoading }">
        <v-loading :is-loading="isOrdersLoading"></v-loading>
        <div class="order-history-block__header">
          <custom-header :title="orderText" :line="'short'"></custom-header>
          <router-link class="order-history-block__header__link" :to="{ name: 'order-history' }">
            <template v-if="!isMobile">Перейти ко всем</template>
            <template v-else>Все</template>
          </router-link>
        </div>
        <div class="order-history-block__container">
          <template v-if="orders.length > 0">
            <template v-if="!isMobile">
              <swiper
                class="orders-slider"
                :slides-per-view="ordersSlidesPerView"
                :space-between="OrdersSpaceBetween"
                :allow-touch-move="true"
                :grab-cursor="true"
              >
                <swiper-slide v-for="order in orders">
                  <order-item
                    :number="order.number"
                    :status="order.status"
                    :date="order.date"
                    :delivery_type="order.delivery_type"
                    :sum="parseInt(order.sum)"
                    :positions="order.positions"
                  ></order-item>
                </swiper-slide>
              </swiper>
            </template>
            <!-- <order-item v-else :number="orders[0].number" :status="orders[0].status" :date="orders[0].date"
              :delivery_type="orders[0].delivery_type" :sum="parseInt(orders[0].sum)"
              :positions="orders[0].positions"></order-item> -->
          </template>
          <p v-else class="order-history-block__text">Заказов не найдено</p>
        </div>
      </section>
      <div class="right-column">
        <section class="shipment-history-block">
          <custom-header :title="'Отгрузки'"></custom-header>
          <div class="shipment-history-block__content" :class="{ loading: isShipmentLoading }">
            <v-loading :is-loading="isShipmentLoading"></v-loading>
            <div class="shipment-history-block__top">
              <div class="shipment-history-block__sum-block">
                <p class="shipment-history-block__sum-block__label">Общая сумма</p>
                <p class="shipment-history-block__sum-block__value">
                  {{ parseInt(shipmentSum).toLocaleString('ru-RU') }} ₽
                </p>
              </div>
              <router-link class="shipment-history-block__link" :to="{ name: 'order-history' }">
                <span>Смотреть все заказы</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 12H21"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 7L21 12L16 17"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
            <table v-if="shipments.length != 0" class="shipment-history-block__table">
              <tr>
                <th>Заказ</th>
                <th>Дата</th>
                <th>Сумма</th>
              </tr>
              <tr v-for="shipment in shipments">
                <td>Заказ №{{ shipment.id }}</td>
                <td>{{ shipment.date }}</td>
                <td>{{ parseInt(shipment.sum).toLocaleString('ru-RU') }} ₽</td>
              </tr>
            </table>
            <p v-else class="shipment-history-block__text">Отгрузки не найдены</p>
          </div>
        </section>
        <section class="accounts-receivable-block">
          <custom-header :title="'Дебиторская задолженность'" :line="'short'"></custom-header>
          <div
            class="accounts-receivable-block__content"
            :class="{ loading: receivablePayments.isLoading }"
          >
            <v-loading :is-loading="receivablePayments.isLoading"></v-loading>
            <div v-if="receivablePayments.sum != ''" class="accounts-receivable-block__sum-block">
              <p class="accounts-receivable-block__sum-block__label">Общая сумма</p>
              <p class="accounts-receivable-block__sum-block__value">
                {{ parseInt(receivablePayments.sum).toLocaleString('ru-RU') }} ₽
              </p>
            </div>
            <p v-else class="accounts-receivable-block__text">Информации нет</p>
            <alert-block
              class="accounts-receivable-block__alert"
              v-if="
                receivablePayments.next_payment_sum != '' &&
                receivablePayments.next_payment_date != ''
              "
              :text="`Ближайший платеж на сумму ${parseInt(
                receivablePayments.next_payment_sum
              ).toLocaleString('ru-RU')} ₽ состоится ${
                receivablePayments.next_payment_date
              } по заказу №${receivablePayments.next_payment_order}`"
            ></alert-block>
          </div>
        </section>
      </div>
    </div>
    <section class="main-page__goods">
      <template v-if="popularGoods.length > 0">
        <custom-header :title="'Популярые позиции'" :line="'short'"></custom-header>
        <div class="good-container">
          <!-- <product-card v-for="good in popularGoods" :key="good.id" :id="good.id" :amount="good.amount"
                :name="good.name" :articul="good.articul" :brand="good.brand" :multiplicity="good.multiplicity"
                :unit="good.unit" :price="good.price" :images="good.images" :added="good.added"
                @addedToCart="(id) => onAddedToCart(popularGoods, id)"
                @amount-change="(id, amount) => onAmountChange(popularGoods, id, amount)"></product-card> -->
          <goods-slider
            :goods="popularGoods"
            @addedToCart="(id) => onAddedToCart(popularGoods, id)"
            @amount-change="(id, amount) => onAmountChange(popularGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
      <template v-if="onsaleGoods.length > 0">
        <custom-header :title="'Акционные товары'" :line="'short'"></custom-header>
        <div class="good-container">
          <goods-slider
            :goods="onsaleGoods"
            @addedToCart="(id) => onAddedToCart(onsaleGoods, id)"
            @amount-change="(id, amount) => onAmountChange(onsaleGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
      <template v-if="orderedGoods.length > 0">
        <custom-header :title="'вы уже заказывали'" :line="'short'"></custom-header>
        <div class="good-container">
          <goods-slider
            :goods="orderedGoods"
            @addedToCart="(id) => onAddedToCart(orderedGoods, id)"
            @amount-change="(id, amount) => onAmountChange(orderedGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
      <template v-if="recommendedGoods.length > 0">
        <custom-header :title="'рекомендованные'" :line="'short'"></custom-header>
        <div class="good-container">
          <goods-slider
            :goods="recommendedGoods"
            @addedToCart="(id) => onAddedToCart(recommendedGoods, id)"
            @amount-change="(id, amount) => onAmountChange(recommendedGoods, id, amount)"
          ></goods-slider>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import MainSearch from '../components/MainSearch.vue'
import CustomHeader from '../components/CustomHeader.vue'
import OrderItem from '../components/OrderItem.vue'
import ProductCard from '../components/ProductCard.vue'
import ProductSlider from '../components/ProductSlider.vue'
import vLoading from '../components/v-loading.vue'
import AlertBlock from '../components/AlertBlock.vue'
import GoodsSlider from '../components/GoodsSlider.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'

import { mapState, mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'
import { useAddedGoods } from '@/stores/addedGoods'
import { useGeneralInfo } from '../stores/generalInfo'
import { useBreadcrumbs } from '../stores/breadcrumbs'

import { serverRequest } from '../api/request'

export default {
  name: 'HomeView',
  components: {
    MainSearch,
    CustomHeader,
    OrderItem,
    ProductCard,
    ProductSlider,
    vLoading,
    AlertBlock,
    GoodsSlider,
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      menuOpen: false,

      isMobile: false,

      orderText: 'Последние заказы',

      ordersSlidesPerView: 2,
      OrdersSpaceBetween: 24,

      orders: [
        // {
        //   id: 1,
        //   number: 1234,
        //   status: 'Ожидает отгрузки',
        //   date: '27/01/2020',
        //   delivery_type: 'Самовывоз',
        //   sum: 200000,
        //   positions: [
        //     {
        //       id: 1,
        //       img: 'good.webp'
        //     }
        //   ]
        // },
      ],

      goods: [
        // {
        //   id: 1,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: '1496',
        //   image: "good.webp",
        //   multiplicity: '1/100',
        //   unit: 'шт'
        // },
      ],

      isOrdersLoading: true,
      isShipmentLoading: true,

      receivablePayments: {
        isLoading: false,
        sum: '',
        next_payment_sum: '',
        next_payment_date: '',
        next_payment_order: ''
      },

      shipmentSum: 0,
      shipments: [],

      popularGoods: [],
      onsaleGoods: [],
      recommendedGoods: [],
      orderedGoods: []
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = []

    if (document.documentElement.clientWidth <= 600) {
      this.isMobile = true
      this.orderText = 'Последний заказ'
    } else {
      this.isMobile = false
      this.orderText = 'Последние заказы'
    }

    if (
      document.documentElement.clientWidth <= 1600 &&
      document.documentElement.clientWidth > 1300
    ) {
      this.ordersSlidesPerView = 1
    }

    this.getRecommendedGoods()
    this.getPopularGoods()
    this.getOnsaleGoods()
    this.getOrderedGoods()
    this.getMainBanner()
    this.getSideBanner()
    this.getOrders()
    this.getShipments()
    this.getPaymentInfo()
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useGeneralInfo, { storeMainBanner: 'mainBanner' }),
    ...mapWritableState(useGeneralInfo, { storeSideBanner: 'sideBanner' }),
    ...mapWritableState(useAddedGoods, ['cartGoods']),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' })
  },

  methods: {
    onAddedToCart(goods, id) {
      let good = goods.find((item) => item.id === id)
      good.added = !good.added

      this.checkAllProducts()
    },

    onAmountChange(goods, id, amount) {
      let good = goods.find((item) => item.id === id)
      good.amount = amount

      this.checkAllProducts()
    },

    checkAllProducts() {
      for (let good of this.onsaleGoods) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }

      for (let good of this.popularGoods) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }

      for (let good of this.recommendedGoods) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }

      for (let good of this.orderedGoods) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }
    },

    checkProducts(array) {
      for (let good of array) {
        let cartGood = this.cartGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }
    },

    async getMainBanner() {
      let result = await serverRequest('GET', 'json', 'get_main_banner', null, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.storeMainBanner.banner = result.banner
        this.storeMainBanner.link = result.link
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getSideBanner() {
      let result = await serverRequest('GET', 'json', 'get_sidebar_banner', null, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.storeSideBanner.banner = result.banner
        this.storeSideBanner.link = result.link
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getPopularGoods() {
      let formData = {
        type: 'popular',
        sort: '',
        amount: 5
      }

      let result = await serverRequest('POST', 'json', 'get_goods', formData, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.popularGoods = result.goods

        this.checkProducts(this.popularGoods)
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getRecommendedGoods() {
      let formData = {
        type: 'recommended',
        sort: '',
        amount: 5
      }

      let result = await serverRequest('POST', 'json', 'get_goods', formData, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.recommendedGoods = result.goods

        this.checkProducts(this.recommendedGoods)
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getOnsaleGoods() {
      let formData = {
        type: 'onsale',
        sort: '',
        amount: 5
      }

      let result = await serverRequest('POST', 'json', 'get_goods', formData, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.onsaleGoods = result.goods

        this.checkProducts(this.onsaleGoods)
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getOrderedGoods() {
      let formData = {
        type: 'ordered_before',
        sort: '',
        amount: 5
      }

      let result = await serverRequest('POST', 'json', 'get_goods', formData, this.userToken)

      if (result != 'error' && result != 'unathorized') {
        this.orderedGoods = result.goods

        this.checkProducts(this.orderedGoods)
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getOrders() {
      this.isOrdersLoading = true

      let formData = {
        search_value: null,
        start_date: null,
        end_date: null,
        type: 0,
        amount: 3,
        start_pos: 0
      }

      let result = await serverRequest(
        'POST',
        'json',
        'get_orders_history',
        formData,
        this.userToken
      )

      if (result != 'error' && result != 'unathorized') {
        this.orders = result.orders
      } else {
        //alert('Ошибка запроса')
      }

      this.isOrdersLoading = false
    },

    async getShipments() {
      this.isShipmentLoading = true

      let formData = {
        amount: 2
      }

      let result = await serverRequest(
        'POST',
        'json',
        'get_shipment_history',
        formData,
        this.userToken
      )

      if (result != 'error' && result != 'unathorized') {
        this.shipmentSum = result.total_sum
        this.shipments = result.shipments
      } else {
        //alert('Ошибка запроса')
      }

      this.isShipmentLoading = false
    },

    async getPaymentInfo() {
      this.receivablePayments.isLoading = true

      let formData = {
        type: 1
      }

      let result = await serverRequest(
        'POST',
        'json',
        'payments/get_payment',
        formData,
        this.userToken
      )

      if (result != 'error' && result != 'unathorized') {
        this.receivablePayments = result
      } else {
        //alert('Ошибка запроса')
      }

      this.receivablePayments.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.main-container {
  margin-top: 16px;

  display: flex;
  column-gap: 24px;

  padding-bottom: 40px;
}

.main-page {
  min-width: 0;

  > div {
    width: 100%;
    min-width: 0;
  }

  &__banner-link {
    display: block;
    width: 100%;
  }

  &__banner {
    width: 100%;
    height: 360px;
    border-radius: 6px;
    object-fit: cover;

    &_placeholder {
      background-color: $bw3;
    }
  }

  &__search {
    margin-top: 32px;
  }

  &__container {
    margin-top: 32px;

    display: flex;
    column-gap: 32px;
    width: 100%;

    min-width: 0;
  }

  .right-column {
    width: 100%;
    max-width: 540px;
  }

  &__goods {
    margin-top: 32px;

    border-radius: 6px;
    background: var(--bw6);

    padding: 40px;

    max-width: 100%;
    width: 100%;

    min-width: 0;

    .custom-header {
      &:not(:first-of-type) {
        margin-top: 32px;
      }
    }
  }
}

.good-container {
  margin-top: 16px;
  min-width: 0;
}

.order-history-block {
  width: 100%;
  max-width: 950px;

  position: relative;

  &.loading {
    pointer-events: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__link {
      @extend %text-medium;
      color: var(--primary);
    }
  }

  &__container {
    margin-top: 16px;
    // display: flex;
    // column-gap: 24px;

    min-width: 0;

    .order-item {
      width: 100%;
    }
  }

  .orders-slider {
    min-width: 0;
    width: 100%;
  }

  &__text {
    padding-top: 16px;
    @extend %text-medium;
  }
}

.shipment-history-block {
  width: 100%;

  &__content {
    margin-top: 16px;

    padding: 18px 24px;

    border-radius: 6px;

    background-color: var(--bw6);

    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  &__text {
    margin-top: 16px;
    @extend %text-medium;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sum-block {
    &__label {
      @extend %text-regular;
      color: var(--bw1);
    }

    &__value {
      margin-top: 8px;

      @extend %H2;
      color: var(--bw0);
    }
  }

  &__link {
    @extend %button;
    display: flex;
    align-items: center;
    column-gap: 12px;

    width: fit-content;
  }

  &__table {
    width: 100%;

    margin-top: 24px;

    th {
      text-align: left;

      @extend %text-regular;
      color: var(--bw1);
    }

    td {
      @extend %text-regular;
      color: var(--bw0);
      padding-top: 5px;
    }
  }
}

.accounts-receivable-block {
  margin-top: 24px;

  &__content {
    margin-top: 16px;

    padding: 18px 24px;

    border-radius: 6px;

    background-color: var(--bw6);

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 32px;

    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  &__alert {
    padding: 8px;
    width: fit-content;
  }

  &__text {
    @extend %text-medium;
    padding: 0 0 32px;
  }

  &__sum-block {
    &__label {
      white-space: nowrap;
      @extend %text-regular;
      color: var(--bw1);
    }

    &__value {
      margin-top: 8px;

      @extend %H2;
      color: var(--bw0);
    }
  }

  &__warning {
    padding: 8px;

    border-radius: 4px;

    background-color: var(--bw4);

    display: flex;
    align-items: center;
    column-gap: 12px;

    max-width: 295px;

    svg {
      flex-shrink: 0;
    }

    p {
      @extend %text-regular;
      color: var(--bw0);

      span {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .main-page {
    &__banner {
      height: 270px;
    }

    &__container {
      margin-top: 24px;

      column-gap: 24px;
    }

    &__search {
      margin-top: 24px;
    }

    .right-column {
      max-width: 100%;
    }

    .good-container {
      .product-card:nth-child(5) {
        display: none;
      }
    }
  }

  .order-history-block {
    max-width: 550px;
  }

  .shipment-history-block {
    width: 100%;

    &__content {
      margin-top: 12px;

      padding: 16px 20px;
    }

    &__link {
      padding: 4px 8px;
    }
  }

  .accounts-receivable-block {
    margin-top: 16px;

    &__content {
      padding: 16px 20px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .main-page {
    &__banner {
      height: 232px;
    }

    .right-column {
      display: flex;

      column-gap: 24px;
      max-width: 100%;
    }

    .shipment-history-block {
      margin-top: 32px;

      width: 50%;

      &__content {
        margin-top: 16px;

        padding: 18px 24px;
      }

      &__link {
        padding: 12px 16px;
      }
    }

    .accounts-receivable-block {
      margin-top: 32px;

      width: 50%;

      &__content {
        height: auto;

        margin-top: 16px;

        padding: 18px 24px;
      }
    }

    &__container {
      flex-direction: column;
    }
  }

  .order-history-block {
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .home-page {
  }

  .main-page {
    &__banner {
      height: 174px;
    }

    &__search {
      margin-top: 16px;
    }

    &__container {
      margin-top: 24px;
    }

    .right-column {
      flex-direction: column;
      row-gap: 24px;
    }

    .shipment-history-block {
      max-width: 100%;
      width: 100%;
    }

    .accounts-receivable-block {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-page {
  }

  .main-page {
    &__banner {
      height: 82px;
    }

    .right-column {
      flex-direction: column;
      row-gap: 24px;
    }

    .shipment-history-block {
      &__top {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 24px;
      }

      &__table {
        display: none;
      }

      &__text {
        display: none;
      }
    }

    .accounts-receivable-block {
      width: 100%;

      .custom-header {
        font-size: 19.5px;
      }
    }
  }
}
</style>
