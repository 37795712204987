<template>
  <section class="product-card" :class="[view, { added: added }]">
    <p class="product-card__attribute" v-if="attribute != null && attribute != ''">
      {{ attribute }}
    </p>
    <div class="product-card__img-container">
      <img
        v-show="images.length > 0 && !isLoading"
        class="product-card__img"
        :src="images[0]"
        alt="изображение продукта"
        @load="isLoading = false"
      />
      <img
        v-show="isLoading"
        class="product-card__img placeholder"
        :class="{ loading: images.length > 0 }"
        src="@/assets/icons/img-placeholder.svg"
        alt="изображение продукта"
      />
      <p class="product-card__added-amount">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2201_26380)">
            <path
              d="M20.8406 7.51127C20.7069 7.3484 20.5074 7.25405 20.2969 7.25405H7.54409L7.16259 5.09455C7.12551 4.88444 6.99491 4.70248 6.80759 4.60003L4.04069 3.08639C3.69997 2.90003 3.27274 3.02514 3.08639 3.36585C2.90003 3.70657 3.02514 4.1338 3.36585 4.32015L5.83654 5.67175L7.841 17.0206C7.10354 17.339 6.58608 18.073 6.58608 18.9258C6.58608 20.0696 7.51649 21 8.6603 21C9.5572 21 10.3228 20.4276 10.6115 19.6289H14.7245C15.0131 20.4276 15.7788 21 16.6758 21C17.8195 21 18.75 20.0696 18.75 18.9258C18.75 17.7821 17.8195 16.8516 16.6758 16.8516C15.7788 16.8516 15.0131 17.424 14.7245 18.2227H10.6115C10.3893 17.608 9.88445 17.1276 9.25452 16.9388L8.99703 15.4805H17.2059C18.5424 15.4805 19.7024 14.5292 19.9639 13.2184C20.0399 12.8376 19.7927 12.4673 19.4119 12.3914C19.0313 12.3155 18.6609 12.5624 18.5849 12.9432C18.4541 13.5987 17.8741 14.0742 17.2059 14.0742H17.1761L17.6609 8.6603H19.4395L19.1653 10.0344C19.0893 10.4152 19.3364 10.7855 19.7172 10.8615C19.7638 10.8708 19.8099 10.8753 19.8556 10.8753C20.184 10.8753 20.4776 10.6439 20.5443 10.3096L20.9864 8.09478C21.0276 7.88824 20.9741 7.67414 20.8406 7.51127ZM12.9492 8.6603V14.0742H11.5758L11.0909 8.6603H12.9492ZM8.6603 19.5938C8.29185 19.5938 7.99233 19.2941 7.99233 18.9258C7.99233 18.5575 8.29185 18.2578 8.6603 18.2578C9.02862 18.2578 9.32813 18.5575 9.32813 18.9258C9.32813 19.2941 9.02848 19.5938 8.6603 19.5938ZM16.6758 18.2578C17.0441 18.2578 17.3438 18.5575 17.3438 18.9258C17.3438 19.2941 17.0441 19.5938 16.6758 19.5938C16.3391 19.5938 16.0606 19.3431 16.0151 19.0186C16.0191 18.9883 16.022 18.9574 16.022 18.9258C16.022 18.8942 16.0191 18.8634 16.0151 18.833C16.0606 18.5086 16.3391 18.2578 16.6758 18.2578ZM7.79238 8.6603H9.67901L10.1639 14.0742H8.7486L7.79238 8.6603ZM15.7642 14.0742H14.3555V8.6603H16.249L15.7642 14.0742Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2201_26380">
              <rect width="18" height="18" fill="white" transform="translate(3 3)" />
            </clipPath>
          </defs>
        </svg>
        <span>{{ getAddedAmount }}</span>
      </p>
    </div>
    <div class="product-card__info">
      <div class="name-price-container">
        <router-link class="product-card__name" :to="{ name: 'product', params: { id: id } }">{{
          name
        }}</router-link>
        <p class="product-card__price">{{ parseInt(getPrice).toLocaleString('ru-RU') }} ₽</p>
      </div>
      <p class="product-card__articul">Арт. {{ articul }}</p>
      <div class="product-card__bottom" :class="{ disabled: !isAvailable }">
        <amount-block :amount="amount" @amountChange="amountChange"></amount-block>
        <button class="product-card__button" type="button" @click="addGoodToCart">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import AmountBlock from './AmountBlock.vue'

import { mapWritableState, mapActions } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'

export default {
  name: 'ProductCard',

  components: {
    AmountBlock
  },

  props: {
    id: {
      type: Number,
      default() {
        return 0
      }
    },
    amount: {
      type: Number,
      default() {
        return 1
      }
    },
    name: {
      type: String,
      default() {
        return 'Товар'
      }
    },
    price: {
      type: String,
      default() {
        return '0'
      }
    },
    brand: {
      type: String,
      default() {
        return 'brand'
      }
    },
    attribute: {
      type: String,
      default() {
        return ''
      }
    },
    unit: {
      type: String,
      default() {
        return 'unit'
      }
    },
    multiplicity: {
      type: String,
      default() {
        return 'multiplicity'
      }
    },
    images: {
      type: Array,
      default() {
        return []
      }
    },
    view: {
      type: String,
      default() {
        return 'normal'
      }
    },
    added: {
      type: Boolean,
      default() {
        return false
      }
    }
  },

  emits: ['addedToCart', 'amountChange'],

  data() {
    return {
      addToCartAmount: 1,
      isLoading: true,
      isImage: true
    }
  },

  created() {},

  beforeUnmount() {},

  computed: {
    ...mapWritableState(useAddedGoods, ['cartGoods']),

    getPrice() {
      return Math.round(parseInt(this.price))
    },

    positionLenght() {
      return this.positions.length
    },

    isAvailable() {
      if (this.attribute == 'снят с продаж') {
        return false
      } else {
        return true
      }
    },

    buttonText() {
      if (this.added) {
        return 'В корзине'
      } else {
        return 'В корзину'
      }
    },

    getAddedAmount() {
      if (this.added) {
        return this.amount
      } else {
        return 0
      }
    }
  },

  methods: {
    ...mapActions(useAddedGoods, { storeSetAmount: 'setAmount' }),
    ...mapActions(useAddedGoods, { storeDeleteGood: 'deleteGood' }),

    amountChange(amount) {
      if (this.added) {
        this.storeSetAmount(this.id, amount)
      }
      this.$emit('amountChange', this.id, amount)
    },

    addGoodToCart() {
      if (this.added) {
        this.storeDeleteGood(this.id)
        this.$emit('addedToCart', this.id)
      } else {
        let newGood = {
          id: this.id,
          name: this.name,
          articul: this.articul,
          brand: this.brand,
          multiplicity: this.multiplicity,
          unit: this.unit,
          amount: this.amount,
          price: this.getPrice,
          images: this.images
        }

        this.cartGoods.push(newGood)

        this.$emit('addedToCart', this.id)
      }
    }
  }
}
</script>

<style lang="scss">
.product-card {
  border-radius: 6px;
  border: 1px solid var(--bw-4, #f4f5f6);

  background-color: var(--bw6);

  padding: 12px;

  max-width: 100%;

  position: relative;

  &__attribute {
    position: absolute;
    top: 20px;
    left: 20px;

    background-color: $primary;
    border-radius: 12px;

    padding: 2px 12px;

    @extend %text-small;
    color: $bw6;

    z-index: 2;
  }

  &__img-container {
    position: relative;
    height: 200px;

    flex-shrink: 0;

    user-select: none;
  }

  &__img {
    width: 100%;
    height: 100%;

    border-radius: 4px;

    object-fit: contain;

    user-select: none;

    &.placeholder {
      background-color: $bw4;

      &.loading {
        background: linear-gradient(#f4f5f6, #d9d9da, #f4f5f6, #d9d9da);
        background: linear-gradient(-90deg, #f4f5f6, #d9d9da, #f4f5f6, #d9d9da);
        background-size: 400% 400%;
        animation: gradient 3s ease infinite;
      }
    }
  }

  &__added-amount {
    position: absolute;
    left: 8px;
    bottom: 8px;

    display: flex;
    column-gap: 4px;
    align-items: center;

    padding: 4px 8px;

    border-radius: 34px;

    background-color: var(--primary);

    span {
      @extend %text-regular;
      color: var(--bw6);
      min-width: 10px;
    }
  }

  &__info {
    width: 100%;
  }

  &__articul {
    display: none;

    @extend %text-small;
    color: var(--bw3);
  }

  &__name {
    margin-top: 16px;

    @extend %text-small;
    color: var(--bw0);

    min-height: 60px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }

  &__price {
    margin-top: 18px;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    white-space: nowrap;

    color: var(--primary);
  }

  &__bottom {
    margin-top: 18px;

    display: flex;
    column-gap: 8px;
  }

  &__button {
    @extend %button;

    padding: 6px 12px;

    border-radius: 6px;

    white-space: nowrap;

    user-select: none;
  }

  &.added {
    .product-card__button {
      // background-color: var(--primary-bg);
      color: var(--bw1);
      background-color: var(--bw4);
    }
  }

  &.plates {
    display: flex;
    column-gap: 24px;

    padding: 12px 24px 12px 12px;

    .product-card {
      &__img-container {
        width: 236px;
        height: 200px;
      }

      &__articul {
        display: block;

        margin-top: 24px;
      }

      &__name {
        margin-top: 0;
        max-width: 300px;
        min-height: 80px;
      }

      &__price {
        margin-top: 0;
      }

      &__bottom {
        justify-content: flex-end;
      }

      &__button {
        width: fit-content;
        padding: 6px 32px;
      }
    }

    .name-price-container {
      margin-top: 12px;

      width: 100%;

      object-fit: contain;

      user-select: none;

      &.placeholder {
        background-color: #f4f5f6;
      }

      &.loading {
        background: linear-gradient(#f4f5f6, #d9d9da, #f4f5f6, #d9d9da);
        background: linear-gradient(-90deg, #f4f5f6, #d9d9da, #f4f5f6, #d9d9da);
        background-size: 400% 400%;
        animation: gradient 3s ease infinite;
      }
    }
  }

  &.lines {
    display: flex;
    column-gap: 24px;

    padding: 12px 24px 12px 12px;

    .product-card {
      &__img-container {
        width: 118px;
        height: 100px;
      }

      &__articul {
        display: none;
      }

      &__name {
        margin-top: 0;
        max-width: 300px;
        min-height: 80px;
      }

      &__price {
        margin-top: 0;
      }

      &__info {
        display: flex;
        column-gap: 40px;
        align-items: center;
      }

      &__bottom {
        justify-content: flex-start;
        margin-top: 10px;
      }

      &__button {
        width: fit-content;
        padding: 6px 32px;
      }
    }

    .name-price-container {
      margin-top: 10px;

      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1600px) {
  .product-card {
    &__img-container {
      height: 180px;
    }

    &__name {
      min-height: 48px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;
    }

    &__price {
      margin-top: 14px;
    }

    &__button {
      padding: 4px 12px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .product-card {
    &__attribute {
      top: 12px;
      left: 12px;
    }

    &__name {
      margin-top: 12px;
    }

    &__price {
      margin-top: 14px;
    }

    &__img-container {
      height: 145px;
    }

    &__bottom {
      margin-top: 10px;
      flex-direction: column;
      row-gap: 8px;

      .amount-block {
        width: 100%;
        justify-content: center;
        column-gap: 20px;
      }
    }

    &__button {
      padding: 6px 12px;
    }

    .amount-block {
      padding: 6px 4px;
    }
  }
}
</style>
