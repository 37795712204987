import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useOrderStatuses = defineStore('orderStatuses', () => {
  const statuses = ref('')

  statuses.value = [
    {
      code: 0,
      text: 'Расчёт стоимости'
    },
    {
      code: 1,
      text: 'Новый заказ'
    },
    {
      code: 2,
      text: 'Подтверждение заказа'
    },
    {
      code: 3,
      text: 'Ожидает оплаты'
    },
    {
      code: 4,
      text: 'Ожидает поставки'
    },
    {
      code: 5,
      text: 'Ожидает отгрузки'
    },
    {
      code: 6,
      text: 'Доставка'
    },
    {
      code: 7,
      text: 'Дебиторская задолженность'
    },
    {
      code: 8,
      text: 'Рекламация'
    },
    {
      code: 9,
      text: 'Просроченная ДЗ'
    },
    {
      code: 10,
      text: 'Завершён'
    },
    {
      code: 11,
      text: 'Отменён'
    },
    {
      code: 12,
      text: 'Оплачен'
    },
    {
      code: 13,
      text: 'Отгружен'
    },
    {
      code: 14,
      text: 'Подтверждён'
    },
    {
      code: 15,
      text: 'В обработке'
    }
  ]

  const getStatus = (code) => {
    if (typeof code !== 'string') {
      if (code == null) {
        return statuses.value[0]
      } else {
        let elem = statuses.value.find((el) => el.code === code)
        return elem
      }
    } else {
      return statuses.value[0]
    }
  }

  const getStatusText = (code) => {
    if (typeof code !== 'string') {
      if (code == null) {
        return 'Не определён'
      } else {
        let elem = statuses.value.find((el) => el.code === code)
        return elem.text
      }
    } else {
      return 'Не определён'
    }
  }

  return {
    statuses,
    getStatus,
    getStatusText
  }
})
